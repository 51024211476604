import React from "react"
import Seo from "../components/Seo"
import Title from "../components/Title"

const contact = () => {
  return (
    <>
      <Seo title="Contact" />
      
      <section className="contact-page">
        <Title title='Contact Us' />
        <article className="contact-form">
          <h3>get in touch</h3>
          <form 
            method='POST'
            action="https://submit-form.com/RnZQcCew"
            >
            <div className="form-group">
            
             <input 
              type="hidden" 
              name="_feedback.whitelabel" 
              value="true" 
              />

              <input
                type="checkbox"
                name="_honeypot"
                style={{display: "none"}}
                tabindex="-1"
                autocomplete="off"
              />

              <input
                type="text"
                name="name"
                placeholder="name"
                className="form-control"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="email"
                className="form-control"
                required
              />
              <textarea
                name="message"
                rows="5"
                placeholder="message"
                className="form-control"
              ></textarea>
            </div>
            <button type="submit" className="submit-btn btn">
              submit here
            </button>
          </form>
        </article>
      </section>
    </>
  )
}

export default contact
